<template>
  <v-app class="ma-0 pa-0">
    <div v-if="isAuth && $route.name != 'visitorValidation' && $route.name != 'register' && $route.name != 'registry-people' && $route.name != 'schedule' && $route.name != 'signIn'" class="ctHeader">
      <div>
        <div class="no-mobile ma-0 pa-0">
          <img src="./assets/logo.png" />
        </div>
        <div class="mobile ma-0 pa-0">
          <img @click.stop="drawer = !drawer" src="./assets/logo.png" />
        </div>
      </div>

       <div class="confTop" ><!-- PARK: LISTA DE APERTURA O SALIDA -->
        <v-menu
          v-if="$route.name == 'dashCars'"
          bottom
          origin="center center"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#BDBDBD"
              dark
              v-bind="attrs"
              v-on="on"
              outlined
              x-small
              class="mt-0 mr-2"
            >
              <p class="ma-0 pa-0">
                ENTRADA
              </p>
              <v-icon small class="ml-1">fal fa-angle-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in $store.getters.parkDevices"
              :key="i"
              style="cursor: pointer"
              class="sede-menu"
            >
              <v-list-item-title @click="sendMsgToBroker(item.type_device + '/' + item.mac + staticEndTopic, 'TAKE_PIC')">{{
                item.name
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu
          v-if="$route.name == 'dashCars'"
          bottom
          origin="center center"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#BDBDBD"
              dark
              v-bind="attrs"
              v-on="on"
              outlined
              x-small
              class="mt-0 mr-1"
            >
              <p class="ma-0 pa-0">
                SALIDA
              </p>
              <v-icon small class="ml-1">fal fa-angle-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in $store.getters.parkDevices"
              :key="i"
              style="cursor: pointer"
              class="sede-menu"
            >
              <v-list-item-title @click="sendMsgToBroker(item.type_device + '/' + item.mac + staticEndTopic, 'TAKE_PIC_2')">{{
                item.name
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="confTop" style="min-width: 80px"><!-- CIRCULO DE NOTIFICACIONES -->
        <!-- NOTIFICACIONES ACCESS -->
          <!-- Black List Notifications -->
        <v-btn
          v-if="$route.name == 'dashboard'"
          class="mr-1"
          fab
          dark
          x-small
          :color="$store.getters.blackListNotifications > 0 ? '#004AAD' : 'grey'"
          :disabled="$store.getters.blackListNotifications === 0"
          @click="openBlackListPopups"
        >
          <v-icon v-if="$route.name == 'dashboard' && $store.getters.blackListNotifications === 0" dark>
            mdi-minus
          </v-icon>
          <p v-else style="color: white" class="font-weight-medium text-body-2">{{ $store.getters.blackListNotifications}} </p>
        </v-btn>
          <!-- Restricted People Notifications -->
        <v-btn
          v-if="$route.name == 'dashboard' && $store.getters.restrictedPopupStatus"
          fab
          dark
          x-small
          :color="$store.getters.restrictedPeopleNotifications > 0 ? '#FFCC80' : 'grey'"
          :disabled="$store.getters.restrictedPeopleNotifications === 0"
          @click="openRestrictedPeoplePopup"
        >
          <v-icon v-if="$route.name == 'dashboard' && $store.getters.restrictedPeopleNotifications === 0" dark>
            mdi-minus
          </v-icon>
          <p v-else style="color: black" class="font-weight-medium text-body-2">{{ $store.getters.restrictedPeopleNotifications}} </p>
        </v-btn>

        <!-- NOTIFICACIONES PARK -->
        <v-btn
          v-if="$route.name == 'dashCars'"
          class="mx-2"
          fab
          dark
          x-small
          :color="$store.getters.parkNotificationTotal > 0 ? '#004AAD' : 'grey'"
          :disabled="$store.getters.parkNotificationTotal === 0"
          @click="openPopups"
        >
          <v-icon v-if="$route.name == 'dashCars' && $store.getters.parkNotificationTotal === 0" dark>
            mdi-minus
          </v-icon>
          <p v-else style="color: white" class="font-weight-medium text-body-2">{{ $store.getters.parkNotificationTotal}} </p>
        </v-btn>

        <!-- NOTIFICACIONES VISION -->
        <v-btn
          v-if="$route.name == 'vision'"
          class="mx-2"
          fab
          dark
          x-small
          :color="$store.getters.visionNotifications > 0 ? '#004AAD' : 'grey'"
          :disabled="$store.getters.visionNotifications === 0"
          @click="openVisionPopups"
        >
          <v-icon v-if="$route.name == 'vision' && $store.getters.visionNotifications === 0" dark>
            mdi-minus
          </v-icon>
          <p v-else style="color: white" class="font-weight-medium text-body-2">{{ $store.getters.visionNotifications }} </p>
        </v-btn>

        <!-- NOTIFICACIONES REGISTRY -->
          <v-menu
            v-if="$route.name == 'registrations' && roleChecker.iHaveGrants(userRole, 'REG-NOTIFICATION-BTN')"
            bottom
            origin="center center"
            transition="slide-y-transition"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            width="250px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                v-bind="attrs"
                v-on="on"
                fab
                dark
                x-small
                :color="$store.getters.registryNotifications && $store.getters.registryNotifications.length > 0 ? '#004AAD' : 'grey'"
                :disabled="!$store.getters.registryNotifications || $store.getters.registryNotifications.length === 0"
              >
                <v-icon v-if="$route.name == 'registrations' && (!$store.getters.registryNotifications || $store.getters.registryNotifications.length  === 0)" dark>
                  mdi-minus
                </v-icon>
                <p v-else style="color: white" class="font-weight-medium text-body-2">{{ $store.getters.registryNotifications ? $store.getters.registryNotifications.length : 0 }} </p>
              </v-btn>
            </template>

            <v-card style="background-color: #212734">
              <v-card-title style="background-color: #31394C">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="d-flex justify-center align-center ma-0 pa-0">
                    <p>Registros para asignacion de sede.</p>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text style="background-color: #212734" class="pa-0 pl-3">
                <div style="max-height: 300px; overflow-y: scroll;
                  overflow-x: hidden;">
                  <v-list 
                    v-for="(item, i) in $store.getters.registryNotifications"
                    :key="i"
                    style="background-color: #212734"
                  >
                    <v-list-item class="ma-0 pa-0">
                      <v-list-item-avatar rounded="1" size="80">
                        <v-img
                          :src="getRegistryImg(item)"
                          :alt="item.first_name"
                          lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                        >
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content style="max-width:250px">
                        <v-list-item-title><small>{{ getRegNames(item) }}</small></v-list-item-title>
                        <v-list-item-subtitle>{{ item.document_number }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="ma-0 py-0 pl-0 pr-3">
                        <div style="display: grid; grid-template-columns: 1fr 0fr; max-width: 300px;align-items: center;">
                            <div>
                              <v-select
                              v-model="item.sede"
                              :items="permissionGroupsList"
                              item-text="name"
                              item-value="uuid"
                              label="Rol"
                              outlined
                              dense
                              hide-details
                              return-object
                            ></v-select>
                            <v-select
                              v-model="item.suborg"
                              :items="suborgsList"
                              item-text="name"
                              item-value="uuid"
                              label="Suborganización"
                              outlined
                              dense
                              hide-details
                              return-object
                              class="pt-2"
                            ></v-select>
                            </div>
                            <!-- // TODO: Old permission asignation way
                              <v-select
                              v-model="item.sede"
                              :items="permissionGroupsList"
                              item-text="name"
                              item-value="uuid"
                              chips
                              multiple
                              label="Sede"
                              outlined
                              hide-details
                              return-object
                            ></v-select> -->
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              x-small
                              color="primary"
                              @click="saveRegSede(item)"
                              :disabled="!item.sede"
                              :loading="item.loading"
                            >
                              <v-icon dark>
                                mdi-content-save
                              </v-icon>
                            </v-btn>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
      </div>

      <div>
        <!-- ACCESS MODULE -->
        <!-- <div v-if="selectedModule === 'dashboard'" class="menuModVision" style="grid-template-columns:1fr 1fr 1fr 1fr; max-width:900px;"> -->
        <div class="no-mobile">
          <div
            v-if="
              $route.name == 'dashboard' ||
              $route.name == 'nominal' ||
              $route.name == 'entryHistory' ||
              ($route.name == 'registrations' && getModulesValidation() != 5 && getModulesValidation() != 6)
            "
            class="menuModVision"
            :style="validateStyle()"
            style="max-width: 900px"
          >
            <div>
              <a
                @click="goTo('dashboard')"
                :class="$route.name == 'dashboard' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >fal fa-window</v-icon
                >
                <p>Dashboard</p>
              </a>
            </div>
            <!-- //TODO: Descomentar cuando se active Nomina <div v-if="roleChecker.iHaveGrants(userRole, 'MENU-PAYROLL') && $store.getters.lunchModuleStatus == false"> -->
            <div v-if="userRole == 'SUPER' || organizationName == 'luxe' || organizationName == 'charlee'">
              <a
                @click="goTo('nominal')"
                :class="$route.name == 'nominal' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >fal fa-money-check-edit-alt</v-icon
                >
                <p>Nómina</p>
              </a>
            </div>
            <!-- <div>
              <a
                @click="goTo('entryHistory')"
                :class="$route.name == 'entryHistory' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >fal fa-file-search</v-icon
                >
                <p>Histórico</p>
              </a>
            </div> -->
            <div>
              <a
                @click="goTo('registrations')"
                :class="$route.name == 'registrations' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >fal fa-address-card</v-icon
                >
                <p>Registros</p>
              </a>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div
            v-if="
              $route.name == 'dashboard' ||
              $route.name == 'nominal' ||
              $route.name == 'entryHistory' ||
              ($route.name == 'registrations' && getModulesValidation() != 5 && getModulesValidation() != 6)
            "
            class="menuModVision"
            style="grid-template-columns:1fr; max-width: 900px"
          >
            <div>
              <v-menu
                bottom
                origin="center center"
                transition="slide-x-transition"
                width="250px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#BDBDBD"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    class="mt-0"
                  >
                    <p class="ma-0 pa-0">
                      {{ $route.name }}
                    </p>
                    <v-icon small class="ml-1">fal fa-angle-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in views"
                    :key="i"
                    style="cursor: pointer"
                    class="sede-menu"
                  >
                    <v-list-item-title @click="goTo(item.id)">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
        <!-- PARK MODULE -->
        <!-- <div v-if="selectedModule === 'dashCars'" class="menuModVision" style="grid-template-columns:1fr; max-width:800px;"> -->
        <div class="no-mobile">
          <div
            v-if="$route.name == 'dashCars' || $route.name == 'vehicleReg' || ($route.name == 'registrations' && (getModulesValidation() == 5 || getModulesValidation() == 6))"
            class="menuModVision"
            style="grid-template-columns: 1fr 1fr 1fr; max-width: 800px"
          >
            <div>
              <a
                @click="goTo('dashCars')"
                :class="$route.name == 'dashCars' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >fal fa-window</v-icon
                >
                <p>Dashboard</p>
              </a>
            </div>
            <div v-if="getModulesValidation() == 5 || getModulesValidation() == 6">
              <a
                @click="goTo('registrations')"
                :class="$route.name == 'registrations' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >fal fa-address-card</v-icon
                >
                <p>Registros</p>
              </a>
            </div>
            <div>
              <a
                @click="goTo('vehicleReg')"
                :class="$route.name == 'vehicleReg' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >fal fa-cars</v-icon
                >
                <p>Vehículos Registrados</p>
              </a>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div
            v-if="$route.name == 'dashCars' || $route.name == 'vehicleReg' || ($route.name == 'registrations' && (getModulesValidation() == 5 || getModulesValidation() == 6))"
            class="menuModVision"
            style="grid-template-columns:1fr; max-width: 900px"
          >
            <div>
              <v-menu
                bottom
                origin="center center"
                transition="slide-x-transition"
                width="250px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#BDBDBD"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    class="mt-0"
                  >
                    <p class="ma-0 pa-0">
                      {{ $route.name }}
                    </p>
                    <v-icon small class="ml-1">fal fa-angle-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in views"
                    :key="i"
                    style="cursor: pointer"
                    class="sede-menu"
                  >
                    <v-list-item-title @click="goTo(item.id)">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
        <!-- VISION MODULE -->
        <!-- <div v-if="selectedModule === 'vision'" class="menuModVision" style="grid-template-columns:1fr 1fr; max-width:400px;"> -->
        <div class="no-mobile">
          <div
            v-if="$route.name == 'settings' || $route.name == 'vision'"
            class="menuModVision"
            style="grid-template-columns: 1fr 1fr; max-width: 400px"
          >
            <div>
              <a
                @click="goTo('settings')"
                :class="$route.name == 'settings' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >mdi-cog-outline</v-icon
                >
                <p>Configuración</p>
              </a>
            </div>
            <div>
              <a
                @click="goTo('vision')"
                :class="$route.name == 'vision' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >mdi-bullhorn-outline</v-icon
                >
                <p>Eventos</p>
              </a>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div
            v-if="$route.name == 'settings' || $route.name == 'vision'"
            class="menuModVision"
            style="grid-template-columns:1fr; max-width: 900px"
          >
            <div>
              <v-menu
                bottom
                origin="center center"
                transition="slide-x-transition"
                width="250px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#BDBDBD"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    class="mt-0"
                  >
                    <p class="ma-0 pa-0">
                      {{ $route.name }}
                    </p>
                    <v-icon small class="ml-1">fal fa-angle-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in views"
                    :key="i"
                    style="cursor: pointer"
                    class="sede-menu"
                  >
                    <v-list-item-title @click="goTo(item.id)">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
        <!-- CONFIG MODULE -->
        <!-- <div v-if="selectedModule === 'config'" class="menuModVision" style="grid-template-columns:1fr 1fr 1fr; max-width:800px;"> -->
        <div class="no-mobile">
          <div
            v-if="
              $route.name == 'devices' ||
              $route.name == 'configuration' ||
              $route.name == 'permissions' ||
              $route.name == 'suborganizations' ||
              $route.name == 'logs'
            "
            class="menuModVision"
            style="max-width: 900px"
            :style="validateConfigStyle()"
          >
            <div>
              <a
                @click="goTo('devices')"
                :class="$route.name == 'devices' ? 'act' : ''"
                class="ma-0 pa-0"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 15px; color: #9ca3af"
                  dark
                  >fal fa-phone-laptop</v-icon
                >
                <p>Dispositivos</p>
              </a>
            </div>
            <div v-if="userRole != 'MONITORING'">
              <a
                @click="goTo('permissions')"
                :class="$route.name == 'permissions' ? 'act' : ''"
                class="ma-0 pa-0"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 15px; color: #9ca3af"
                  dark
                  >fal fa-users-cog</v-icon
                >
                <p style="white-space: nowrap;">Conf. Permisos</p>
              </a>
            </div>
            <div v-if="userRole != 'MONITORING'">
              <a
                @click="goTo('suborganizations')"
                :class="$route.name == 'suborganizations' ? 'act' : ''"
                class="ma-0 pa-0"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 15px; color: #9ca3af"
                  dark
                  >fal fa-sitemap</v-icon
                >
                <p>Suborgs</p>
              </a>
            </div>
            <div v-if="userRole == 'SUPER' || organizationName == 'luxe' || organizationName == 'charlee'">
              <a
                @click="goTo('configuration')"
                :class="$route.name == 'configuration' ? 'act' : ''"
                class="ma-0 pa-0"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 15px; color: #9ca3af"
                  dark
                  >fal fa-clipboard-list-check</v-icon
                >
                <p style="white-space: nowrap;">Conf. Nómina</p>
              </a>
            </div>
            <div v-if="roleChecker.iHaveGrants(userRole, 'MENU-LOGS')">
              <a
                @click="goTo('logs')"
                :class="$route.name == 'logs' ? 'act' : ''"
                class="ma-0 pa-0"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 15px; color: #9ca3af"
                  dark
                  >fal fa-cog</v-icon
                >
                <p>Logs</p>
              </a>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div
            v-if="
              $route.name == 'devices' ||
              $route.name == 'configuration' ||
              $route.name == 'permissions' ||
              $route.name == 'suborganizations' ||
              $route.name == 'logs'
            "
            class="menuModVision"
            style="grid-template-columns:1fr; max-width: 900px"
          >
            <div>
              <v-menu
                bottom
                origin="center center"
                transition="slide-x-transition"
                width="250px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#BDBDBD"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    class="mt-0"
                  >
                    <p class="ma-0 pa-0">
                      {{ $route.name }}
                    </p>
                    <v-icon small class="ml-1">fal fa-angle-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in views"
                    :key="i"
                    style="cursor: pointer"
                    class="sede-menu"
                  >
                    <v-list-item-title @click="goTo(item.id)">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
        <!-- VISIONID MODULE -->
        <div class="no-mobile">
          <div
            v-if="
              $route.name == 'visionid' || $route.name == 'config-visionid' ||
              ($route.name == 'registrations_visionid' && getModulesValidation() != 5 && getModulesValidation() != 6)
            "
            class="menuModVision"
            :style="validateStyle()"
            style="max-width: 900px"
          >
            <div>
              <a
                @click="goTo('visionid')"
                :class="$route.name == 'visionid' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >fal fa-window</v-icon
                >
                <p>Dashboard VisionID</p>
              </a>
            </div>
            <div>
              <a
                @click="goTo('registrations_visionid')"
                :class="$route.name == 'registrations_visionid' ? 'act' : ''"
              >
                <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >fal fa-address-card</v-icon
                >
                <p>Registros</p>
              </a>
            </div>
            <div>
              <a
                @click="goTo('config-visionid')"
                :class="$route.name == 'config-visionid' ? 'act' : ''"
              >
              <v-icon
                  class="mb-1 mr-2"
                  style="font-size: 21px; color: #9ca3af"
                  dark
                  >mdi-cog-outline</v-icon
                >
                <p>Config.</p>
              </a>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div
            v-if="$route.name == 'visionid' || $route.name == 'registrations_visionid' || $route.name == 'config-visionid'"
            class="menuModVision"
            style="grid-template-columns:1fr; max-width: 900px"
          >
            <div>
              <v-menu
                bottom
                origin="center center"
                transition="slide-x-transition"
                width="250px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#BDBDBD"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    class="mt-0"
                  >
                    <p class="ma-0 pa-0">
                      {{ $route.name }}
                    </p>
                    <v-icon small class="ml-1">fal fa-angle-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in views"
                    :key="i"
                    style="cursor: pointer"
                    class="sede-menu"
                  >
                    <v-list-item-title @click="goTo(item.id)">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </div>

      <div class="confTop">
        <a
          class="no-mobile"
          @click="
            selectedModule = 'config';
            goTo('devices');
          "
          v-if="getMenuStatus() && getModulesValidation() != 4 && getModulesValidation() != 7 && getModulesValidation() != 10 && roleChecker.iHaveGrants(userRole, 'MENU-DEVICES')"
        >
          <v-icon style="color: #9ca3af" dark>fal fa-cog</v-icon>
        </a>
      </div>

      <div class="icoUser no-mobile">
        <v-menu
          bottom
          offset-y
          origin="center center"
          transition="slide-y-transition"
          :close-on-content-click="false"
          width="250px"
          :disabled="!roleChecker.iHaveGrants(userRole, 'REG-UPDATE')"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mb-1 userConfig"
              style="font-size: 21px; background-color: #f87171"
              dark
              v-bind="attrs"
              v-on="on"
              >fal fa-user-cog</v-icon
            >
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-title>Notif. Restringidos: </v-list-item-title>
                <v-list-item-action>
                  <v-switch
                    inset
                    v-model="restrictedPopupsStatus"
                    color="secondary"
                    @click="updateShowRestrictedPeopleStatus(restrictedPopupsStatus)"
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
      <div class="pr-2 no-mobile-2">
        <p class="ma-0 pa-0">{{ getName() }}</p>
        <!--p class="ma-0 pa-0">{{ getName('sede') }}</p-->
        <p
          class="ma-0 pa-0"
          style="
            transform: scale(0.8);
            margin-left: -24px !important;
            min-width: 200px;
          "
          v-if="GZ_data"
        >
          <v-menu
            bottom
            origin="center center"
            transition="slide-x-transition"
            width="250px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#BDBDBD"
                dark
                v-bind="attrs"
                v-on="on"
                outlined
                small
                class="mt-0"
                style=""
                :disabled="$route.name != 'dashboard' && $route.name != 'devices' && $route.name != 'entryHistory' || ($route.name == 'devices' && roleChecker.iHaveGrants(userRole, 'DEVICES-MONITORING'))"
              >
                <!-- <div v-for="(item, i) in sedes" :key="i">
                  <p class="ma-0 pa-0">
                    {{ item.uuid == selectedSede ? item.name : "" }}
                  </p>
                </div> -->
                <p class="ma-0 pa-0">
                  <!-- {{ getSelectedSede() }} -->
                  {{ GZ_data.name }}<!-- // **NEW -->
                </p>
                <v-icon small class="ml-1">fal fa-angle-down</v-icon>
              </v-btn>
            </template>

            <!-- <v-list>
              <v-list-item
                v-for="(item, i) in sedes"
                :key="i"
                style="cursor: pointer"
                class="sede-menu"
              >
                <v-list-item-title @click="selectSede(item)">{{
                  item.name
                }}</v-list-item-title>
              </v-list-item>
            </v-list> -->
            <v-list><!-- // **NEW -->
              <v-list-item
                v-for="(item, i) in groupedZones"
                :key="i"
                style="cursor: pointer"
                class="sede-menu"
              >
                <v-list-item-title @click="selectGroupedZone(item)">{{
                  item.name
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </p>
        <p class="ma-0 pa-0">{{ getName("org") }}</p>
      </div>
    </div>
    <div v-if="isAuth && $route.name != 'visitorValidation' && $route.name != 'register' && $route.name != 'registry-people' && $route.name != 'schedule' && $route.name != 'signIn'" class="bgWhileTop"></div>
    <!--boxCloser-->
    <div v-if="isAuth && $route.name != 'visitorValidation' && $route.name != 'register' && $route.name != 'registry-people' && $route.name != 'schedule' && $route.name != 'signIn'" class="bgCloser" style="display: none"></div>
    <!--menu drawer-->
    <div class="no-mobile">
      <div v-if="isAuth && $route.name != 'visitorValidation' && $route.name != 'register' && $route.name != 'registry-people' && $route.name != 'schedule' && $route.name != 'signIn'" class="ctDrawerMenu">
        <ul class="pl-0" v-if="getMenuStatus()">
          <li style="display: none">
            <v-icon class="fa-bars" style="color: #9ca3af" dark
              >fal fa-bars</v-icon
            >
          </li>
          <li
            v-if="
              (getModulesValidation() == 0 ||
                getModulesValidation() == 1 ||
                getModulesValidation() == 2 ||
                getModulesValidation() == 3 ||
                getModulesValidation() == 8 ||
                getModulesValidation() == 11 ||
                getModulesValidation() == 12 ||
                getModulesValidation() == 14) &&
              organizationName !== 'conconcreto' &&
              organizationName != 'demo'
            "
            :class="selectedModule == 'dashboard' ? 'act' : ''"
          >
            <a
              @click="
                selectedModule = 'dashboard';
                goTo('dashboard');
              "
            >
              <v-icon style="color: #9ca3af" dark>fal fa-id-card-alt</v-icon>
              <p>Access</p>
            </a>
            <span class="tooltipMenu">Access</span>
          </li>
          <li
            v-if="
              (getModulesValidation() == 2 || getModulesValidation() == 3 || getModulesValidation() == 5 || getModulesValidation() == 6 || getModulesValidation() == 9 || getModulesValidation() == 11 || getModulesValidation() == 13 || getModulesValidation() == 14) &&
              roleChecker.iHaveGrants(userRole, 'MENU-CARS')
            "
            :class="selectedModule == 'dashCars' ? 'act' : ''"
          >
            <a
              @click="
                selectedModule = 'dashCars';
                goTo('dashCars');
              "
            >
              <v-icon style="color: #9ca3af" dark>fal fa-car-side</v-icon>
              <p>Park</p>
            </a>
            <span class="tooltipMenu">Park</span>
          </li>
          <li
            v-if="getModulesValidation() == 1 ||
              getModulesValidation() == 3 ||
              getModulesValidation() == 4 ||
              getModulesValidation() == 6 ||
              getModulesValidation() == 10 ||
              getModulesValidation() == 12 ||
              getModulesValidation() == 13 ||
              getModulesValidation() == 14
            "
            :class="selectedModule == 'vision' ? 'act' : ''"
          >
            <a
              @click="
                selectedModule = 'vision';
                goTo('vision');
              "
            >
              <v-icon style="color: #9ca3af" dark>fal fa-eye</v-icon>
              <p>Vision</p>
            </a>
            <span class="tooltipMenu">Vision</span>
          </li>
          <li
            v-if="getModulesValidation() == 7 ||
              getModulesValidation() == 8 ||
              getModulesValidation() == 9 ||
              getModulesValidation() == 10 ||
              getModulesValidation() == 11 ||
              getModulesValidation() == 12 ||
              getModulesValidation() == 13 ||
              getModulesValidation() == 14
            "
            :class="selectedModule == 'visionid' ? 'act' : ''"
          >
            <a
              @click="
                selectedModule = 'visionid';
                goTo('visionid');
              "
            >
              <v-icon style="color: #9ca3af" dark>fal fa-cctv</v-icon>
              <p>VisionID</p>
            </a>
            <span class="tooltipMenu">VisionID</span>
          </li>
          <!--li>
            <a href="#" @click="selectedModule = 'config' ; goTo('devices')">
              <v-icon style="color: #9ca3af" dark>fal fa-cog</v-icon>
              <p>Configuración</p>
            </a>
            <span class="tooltipMenu">Configuración</span>
          </li-->
          <li class="doorClose">
            <a @click="doLogout()">
              <v-icon style="color: #9ca3af" dark>fal fa-door-open</v-icon>
              <p>Cerrar sesión</p>
            </a>
            <span class="tooltipMenu" style="white-space: nowrap"
              >Cerrar sesión</span
            >
          </li>
        </ul>
      </div>
    </div>

    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      v-if="isAuth && $route.name != 'visitorValidation' && $route.name != 'register' && $route.name != 'registry-people' && $route.name != 'schedule' && $route.name != 'signIn'"
    >
      <v-list
        nav
        dense
        v-if="getMenuStatus()"
      >
        <v-list-item-group>
          <v-list-item class="mb-0 pb-0" disabled>
            <v-list-item-title>
              <p class="ma-0 pa-0 text-h6" style="color: #60a5fa;">{{ getName() }}</p>
              <p class="ma-0 pa-0 text-body-2">{{ getName("org") }}</p>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="mb-0 pb-0" disabled>
            <p class="pb-0 mb-0 text-body-1">Zona:</p>
          </v-list-item>
          <v-list-item class="mt-0 pt-0">              
            <v-menu
              bottom
              origin="center center"
              transition="slide-x-transition"
              width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#BDBDBD"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  class="mt-0"
                  style=""
                  :disabled="$route.name != 'dashboard' && $route.name != 'devices' && $route.name != 'entryHistory' || ($route.name == 'devices' && roleChecker.iHaveGrants(userRole, 'DEVICES-MONITORING'))"
                >
                  <!-- <div v-for="(item, i) in sedes" :key="i">
                    <p class="ma-0 pa-0">
                      {{ item.uuid == selectedSede ? item.name : "" }}
                    </p>
                  </div> -->
                  <p class="ma-0 pa-0">
                    <!-- {{ getSelectedSede() }} -->
                    {{ GZ_data.name }}<!-- // **NEW -->
                  </p>
                  <v-icon small class="ml-1">fal fa-angle-down</v-icon>
                </v-btn>
              </template>

              <!-- <v-list>
                <v-list-item
                  v-for="(item, i) in sedes"
                  :key="i"
                  style="cursor: pointer"
                  class="sede-menu"
                >
                  <v-list-item-title @click="selectSede(item)">{{
                    item.name
                  }}</v-list-item-title>
                </v-list-item>
              </v-list> -->
              <v-list><!-- // **NEW -->
                <v-list-item
                  v-for="(item, i) in groupedZones"
                  :key="i"
                  style="cursor: pointer"
                  class="sede-menu"
                >
                  <v-list-item-title @click="selectGroupedZone(item)">{{
                    item.name
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list-item-group> 
      </v-list>
      <v-divider v-if="getMenuStatus()"></v-divider>
      <v-list
        nav
        dense
        v-if="getMenuStatus()"
      >
        <v-list-item-group>
          <v-list-item 
            v-if="
              (getModulesValidation() == 0 ||
                getModulesValidation() == 1 ||
                getModulesValidation() == 2 ||
                getModulesValidation() == 3 || 
                getModulesValidation() == 8 ||
                getModulesValidation() == 11 ||
                getModulesValidation() == 12 ||
                getModulesValidation() == 14) &&
              organizationName !== 'conconcreto' &&
              organizationName != 'demo'
            "
            @click="
              selectedModule = 'dashboard';
              buildMenu('dashboard');
              goTo('dashboard')
            "
          >
            <v-list-item-title>
              <p class="text-body-1">
                <v-icon class="mr-2" style="color: #9ca3af" dark>fal fa-id-card-alt</v-icon> 
                Access
              </p>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="
              (getModulesValidation() == 2 || getModulesValidation() == 3 || getModulesValidation() == 5 || getModulesValidation() == 6 || getModulesValidation() == 9 || getModulesValidation() == 11 || getModulesValidation() == 13 || getModulesValidation() == 14) &&
              roleChecker.iHaveGrants(userRole, 'MENU-CARS')
            "
            @click="
              selectedModule = 'dashCars';
              buildMenu('dashCars');
              goTo('dashCars')
            "
          >
            <v-list-item-title>
              <p class="text-body-1">
                <v-icon class="mr-2" style="color: #9ca3af" dark>fal fa-car-side</v-icon> 
                Park
              </p>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="getModulesValidation() == 1 ||
              getModulesValidation() == 3 ||
              getModulesValidation() == 4 ||
              getModulesValidation() == 6 ||
              getModulesValidation() == 10 ||
              getModulesValidation() == 12 ||
              getModulesValidation() == 13 ||
              getModulesValidation() == 14
            "
            @click="
              selectedModule = 'vision';
              buildMenu('vision');
              goTo('vision')
            "
          >
            <v-list-item-title>
              <p class="text-body-1">
                <v-icon class="mr-2" style="color: #9ca3af" dark>fal fa-eye</v-icon> 
                Vision
              </p>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="getModulesValidation() == 7 ||
              getModulesValidation() == 8 ||
              getModulesValidation() == 9 ||
              getModulesValidation() == 10 ||
              getModulesValidation() == 11 ||
              getModulesValidation() == 12 ||
              getModulesValidation() == 13 ||
              getModulesValidation() == 14
            "
            @click="
              selectedModule = 'visionid';
              buildMenu('visionid');
              goTo('visionid')
            "
          >
            <v-list-item-title>
              <p class="text-body-1">
                <v-icon class="mr-2" style="color: #9ca3af" dark>fal fa-cctv</v-icon> 
                VisionID
              </p>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider v-if="getMenuStatus()"></v-divider>
      <v-list
        nav
        dense
      >
        <v-list-item-group>
          <v-list-item 
            @click="
              selectedModule = 'config';
              buildMenu('devices');
              goTo('devices')
            "
            v-if="getMenuStatus() && getModulesValidation() != 4 && getModulesValidation() != 7 && getModulesValidation() != 10 && roleChecker.iHaveGrants(userRole, 'MENU-DEVICES')"
          >
            <v-list-item-title>
              <p class="text-body-1">
                <v-icon class="mr-2" style="color: #9ca3af" dark>fal fa-cog</v-icon> 
                Configuración
              </p>
            </v-list-item-title>
          </v-list-item>


          <v-list-item @click="doLogout()">
            <v-list-item-title>
              <p class="text-body-1">
                <v-icon class="mr-2" style="color: #9ca3af" dark>fal fa-door-open</v-icon> 
                Cerrar Sesión
              </p>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>


    <v-main
      :class="$route.name != 'login' && $route.name != 'authTest' && $route.name != 'politics' && $route.name != 'visitorValidation' && $route.name != 'register' && $route.name != 'registry-people' && $route.name != 'schedule' && $route.name != 'signIn' ? 'border' : ''"
      style="background-color: #212734 !important"
    >
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<style lang="scss" src="./scss/style.scss"></style>

<script>
import axios from "axios";
import globals from "./globals";
import auth from "./services/auth";
import roles from "./services/role";
import backlog from "./services/logs";
import { Auth } from 'aws-amplify';
//icons library
import "@/scss/all.min.css";
import "@/scss/regular.min.css";
import "@/scss/light.min.css";

export default {
  name: "App",
  components: {},
  data: () => ({
    drawer: false,
    group: null,
    isAuth: auth.loggedIn(),
    roleChecker: roles,
    userRole: auth.getRoleName(),
    username: auth.getUsername(),
    nav_org: auth.get_org(),
    nav_sede: auth.get_org_sede(),
    GZ_data: auth.getGZ(),
    organizationName: auth.getUserEnterpriseName(),
    sedeName: "",
    selectedSede: null,
    devices: [],
    staticEndTopic: "/vehicle-access/response",
    sedes: [],
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    showMenu: false,
    vanguardAI: true,
    vanguardPark: false,
    vanguardAccess: false,
    selectedModule: null,
    allSedesList: [],
    permissionGroupsList: [],
    suborgsList: [],
    groupedZones: [],// **NEW
    views: [
      {id: "dashboard", name: "Dashboard"}, 
      //TODO: Descomentar cuando se active Nomina{id: "nominal", name: "Nómina"}, 
      //{id: "entryHistory", name: "Histórico"}, 
      {id: "registrations", name: "Registros"}],

    userSession: null,
    restrictedPopupsStatus: null
  }),
  methods: {
    activateRestrictedPeople() {
      this.$store.commit("asignRestrictedPopupStatus", !this.$store.getters.restrictedPopupStatus);
    },

    buildMenu: function (route) {
      if (route == 'dashboard') {
        if (this.userRole !== "SUPER") { 
          /* this.views = [
            {id: "dashboard", name: "Dashboard"},
            //{id: "entryHistory", name: "Histórico"}, 
            {id: "registrations", name: "Registros"}
          ] */
          if (this.organizationName == 'luxe' || this.organizationName == 'charlee') {
            this.views = [
              {id: "dashboard", name: "Dashboard"},
              {id: "nominal", name: "Nómina"}, 
              //{id: "entryHistory", name: "Histórico"}, 
              {id: "registrations", name: "Registros"}
            ]
          } else {
            this.views = [
              {id: "dashboard", name: "Dashboard"},
              {id: "registrations", name: "Registros"}
            ]
          }
        } else {
          this.views = [
            {id: "dashboard", name: "Dashboard"},
            {id: "nominal", name: "Nómina"}, 
            //{id: "entryHistory", name: "Histórico"}, 
            {id: "registrations", name: "Registros"}
          ]
        }
      } else if (route == 'dashCars') {
        this.views = [
          {id: "dashCars", name: "Dashboard"},
          {id: "vehicleReg", name: "Vehículos Reg."}
        ]
      } else if (route == 'vision') {
        this.views = [
          {id: "vision", name: "Eventos"},
          {id: "settings", name: "Configuración"},
        ]
      } else if (route == 'visionid') {
        this.views = [
          {id: "visionid", name: "Dashboard VisionID"},
          {id: "registrations", name: "Registros"},
          {id: "config-visionid", name: "Config."}
        ]
      } else if (route == 'devices') {
        if (this.userRole == "SUPER") { 
          this.views = [
            {id: "devices", name: "Dispositivos"},
            {id: "permissions", name: "Conf. Permisos"}, 
            {id: "suborganizations", name: "Suborgs"}, 
            {id: "configuration", name: "Conf. Nómina"},
            {id: "logs", name: "Logs"}
          ]
        } else if (this.userRole == "MONITORING") {
          this.views = [
            {id: "devices", name: "Dispositivos"}
          ]
        } else {
          if (this.organizationName == 'luxe' || this.organizationName == 'charlee') {
            this.views = [
              {id: "devices", name: "Dispositivos"},
              {id: "permissions", name: "Conf. Permisos"}, 
              {id: "suborganizations", name: "Suborgs"}, 
              {id: "configuration", name: "Conf. Nómina"}
            ]
          } else {
            this.views = [
              {id: "devices", name: "Dispositivos"},
              {id: "permissions", name: "Conf. Permisos"}, 
              {id: "suborganizations", name: "Suborgs"}, 
              //TODO: Descomentar cuando se active Nomina  {id: "configuration", name: "Conf. Nómina"}
            ]
          }
        }
      }
    },

    validateStyle() {
      if (this.$store.getters.lunchModuleStatus == false) {
        if (this.userRole !== "SUPER") {
          if (this.organizationName == 'luxe' || this.organizationName === 'charlee' || this.$route.name == 'visionid' || this.$route.name == 'config-visionid' ||
            this.$route.name == 'registrations_visionid') {
            return 'grid-template-columns: 1fr 1fr 1fr;'
          } else {
            return 'grid-template-columns: 1fr 1fr;'    
          }
        } else if (this.userRole == "SUPER") {
          return 'grid-template-columns: 1fr 1fr 1fr;'
        }
      } else {
        return 'grid-template-columns: 1fr 1fr;'
      }
    },

    validateConfigStyle() {
      if (this.roleChecker.iHaveGrants(this.userRole, 'MENU-LOGS')) {
        return 'grid-template-columns:1fr 1fr 1fr 1fr 1fr;'
      } else if (this.roleChecker.iHaveGrants(this.userRole, 'DEVICES-MONITORING')) {
        return 'grid-template-columns:1fr;'
      } else if (this.organizationName == 'luxe' || this.organizationName == 'charlee') {
        return 'grid-template-columns:1fr 1fr 1fr 1fr;'
      } else {
        return 'grid-template-columns:1fr 1fr 1fr;'
      }
    },

    getRegNames(item) {
      return item.first_name + " " + item.first_last_name + " " + item.second_last_name;
    },

    saveRegSede(item) {
      item.loading = true;
      
      if ((item.sede && item.sede.need_acceptance == false) || (item.sede && item.sede.need_acceptance == true && item.suborg) ) {
        let sedesQueryBody = []
        let suborgBody = null;
        let visitorData = null;

        for (let i = 0; i < item.sede.places.length; i++) {
          const element = item.sede.places[i];
          
          sedesQueryBody.push({campus_id: element, company_person_id: item.company_person.uuid})
        }
        console.log("COMPANY PERSON UUID: ", item.company_person.uuid)
        console.log("SEDES: ", sedesQueryBody)

        if (item.sede.need_acceptance) {
          visitorData = {
            uuid: item.company_person.uuid,
            document_number: item.document_number,
            company_id: auth.getUserEnterpriseID(),
            sede_id: auth.getSelectedSede(),
            allowed_entry: false
          }
        }

        if (item.suborg) {
          suborgBody = [{
            sub_org_id: item.suborg.uuid,
            company_person_id: item.company_person.uuid
          }]
        }
        console.log("Visitor", visitorData)

        axios
          .post(globals.APIURL + "registry/asr", {
            company_person_id: item.company_person.uuid,
            campus: sedesQueryBody,
            suborgs: suborgBody,
            role_uuid: item.sede.uuid,
            visitor: visitorData
          })
          .then((res) => {
            if (res && res.status == 200 && res.data === "Sede asign successful") {
              backlog.generateLog({
                enterprise: this.organization.name,
                username: auth.getUsername(),
                role: auth.getRoleName(),
                action: "asign-role/registry",
                observation: item.document_number ? item.document_number : ""
              });
              item.loading = false
              for (let i = 0; i < this.$store.getters.registryNotifications.length; i++) {
                const element = this.$store.getters.registryNotifications[i];
                if (element.document_number === item.document_number) {
                  this.$store.commit('removeRegistryAsigned', i)
                }
              }

              this.snackbar = true;
              this.snackbarColor = "success";
              this.snackbarText = "Se agregó la persona a la sede correctamente.";
              this.$bus.$emit("updateRegistry", "Update register list");
            }
            
            item.loading = false
          })
          .catch((err) => {
            item.loading = false
            console.log(err.response);
          });
      } else if (item.sede.need_acceptance == true && !item.suborg) {
        this.snackbar = true
        this.snackbarColor = "red"
        this.snackbarText = "Si selecciona un grupo de permisos con autorización previa debe seleccionar una suborganización."
        item.loading = false;
      }
    },

    saveRegSedeOld(item) { // TODO: Old permission asignation way 
      item.loading = true;
      if (item.sede.length > 0) {
        let sedesQueryBody = []

        for (let i = 0; i < item.sede.length; i++) {
          const element = item.sede[i];
          
          sedesQueryBody.push({campus_id: element.uuid, company_person_id: item.company_person.uuid})
        }

        
        axios
          .post(globals.APIURL + "registry/asr", {
            company_person_id: item.company_person.uuid,
            campus: sedesQueryBody,
          })
          .then((res) => {
            if (res && res.status == 200 && res.data === "Sede asign successful") {
              item.loading = false
              for (let i = 0; i < this.$store.getters.registryNotifications.length; i++) {
                const element = this.$store.getters.registryNotifications[i];
                if (element.document_number === item.document_number) {
                  this.$store.commit('removeRegistryAsigned', i)
                }
              }

              this.snackbar = true;
              this.snackbarColor = "success";
              this.snackbarText = "Se agregó la persona a la sede correctamente.";
              this.$bus.$emit("updateRegistry", "Update register list");
            }
            
            item.loading = false
          })
          .catch((err) => {
            item.loading = false
            console.log(err.response);
          });  
      } else {
        this.snackbar = true;
        this.snackbarColor = "red";
        this.snackbarText = "Es necesario que seleccione al menos una sede.";
        item.loading = false;
      }
    },

    async buildParams() {

      this.userSession = await Auth.currentAuthenticatedUser();

      if (this.userSession && this.userSession.attributes['custom:restrictedPopups'] === 'true') {
        this.restrictedPopupsStatus = true
        this.$store.commit("asignRestrictedPopupStatus", true);
      } else {
        this.restrictedPopupsStatus = false
        this.$store.commit("asignRestrictedPopupStatus", false);
      }
      
    },

    async updateShowRestrictedPeopleStatus(changeStatus) {
      try {
        let restrictedPopupsStatus;
        if (changeStatus === true) {
          restrictedPopupsStatus = 'true'
        } else if (changeStatus === false) {
          restrictedPopupsStatus = 'false'
        }

        const result = await Auth.updateUserAttributes(this.userSession, {
          'custom:restrictedPopups': restrictedPopupsStatus
        });

        if (result === 'SUCCESS') {
          this.buildParams();
          this.snackbarText = "Se actualizó el estado de la ventana emergente de personas restringidas.";
          this.snackbarColor = "success";
          this.snackbar = true;
        }
      } catch (error) {
        this.snackbarText = "Hubo algun error al intentar actualizar el estado, intentalo más tarde.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
  },

    getRegistryImg(item) {
      return globals.APIURL + 'entry/registry-pic/' + item.register_image;
    },

    getModulesValidation() {
      return auth.getModules();
    },

    getSelectedSede() {
      return auth.getSedeName()
    },

    openBlackListPopups() {
      this.$bus.$emit("popupsBlackList", "Opening black list popups")
    },

    openRestrictedPeoplePopup() {
      this.$bus.$emit("popupRestrictedPeople", "Opening restricted people popups") 
    },

    openPopups() {
      this.$bus.$emit("pupups", "Opening popups");
    },

    openVisionPopups () {
      this.$bus.$emit("popupsVision", "Opening vision popups");
    },

    sendMsgToBroker(topic, msg) {
      axios
        .post(globals.APIURL + "vehicle/bcs", {
          topic: topic,
          message: msg,
        })
        .then((res) => {
          console.log("RESPUESTA TAKE_PICS: ", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMenuStatus() {
      if (
        this.$route.name !== "mainModule" &&
        this.$route.name !== "generalDash"
      ) {
        return true;
      } else {
        return false;
      }
    },

    selectSede(item) {
      let modules = [];
      let modulePlan = null;
      item.devices.forEach((element) => {
        if (!modules.includes(element.type_device)) {
          modules.push(element.type_device);
        }
      });
      if (
        modules.includes("vsgate") &&
        modules.includes("vspark") &&
        modules.includes("vision")
      ) {
        modulePlan = 3;
      } else if (
        modules.includes("vsgate") &&
        modules.includes("vspark") &&
        !modules.includes("vision")
      ) {
        modulePlan = 2;
      } else if (
        modules.includes("vsgate") &&
        !modules.includes("vspark") &&
        !modules.includes("vision")
      ) {
        modulePlan = 1;
      }
      localStorage.setItem("selected-sede", item.uuid);
      localStorage.setItem("sede-name", item.name);
      localStorage.setItem("modules", modulePlan);
      this.$router.go();
    },

    // **NEW
    selectGroupedZone(item) {
      console.log("Grouped Zone: ", item)
      localStorage.setItem("grouped-zones", JSON.stringify({name: item.name, zones: item.zones}));
      this.$router.go();
    },

    async doLogout() {
      backlog.generateLog({
        enterprise: auth.getUserEnterpriseName(),
        username: auth.getUsername(),
        role: auth.getRole(),
        action: "logout",
      });
      auth.logout();
      await Auth.signOut();
      this.isAuth = false;
      this.drawer = false;
      this.$router.push("/");
    },

    getName(value) {
      if (auth.getName() && auth.getLastname()) {
        if (value == "initials") {
          return auth.getName().substr(0, 1) + auth.getLastname().substr(0, 1);
        } else if (value === "org") {
          return auth.get_org();
        } else if (value === "sede") {
          if (!auth.getSedeName()) {
            return auth.get_org_sede();
          } else {
            return auth.getSedeName();
          }
        } else {
          return auth.getName()  + " " + auth.getLastname();
        }
      }
    },

    goTo(route) {
      if (route !== this.$route.name) {
        this.$router.replace({ name: route });
      }
    },

    getPermissionGroups() {
      axios({
        method: "GET",
        baseURL: globals.APIURL + "permission-group/get-pg/" + auth.get_asset_id(),
      }).then((data) => {
        //console.log("LISTA DE GRUPOS DE PERMISO: ", data)
        if (data.data.length > 0) {
          this.permissionGroupsList = data.data
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    getSuborgs() {
      axios
        .post(globals.APIURL + "campus/fdso", {
          company_id: auth.getUserEnterpriseID()
        })
        .then((res) => {
          console.log(res)
          if (res.data && res.data.length > 0) {
            this.suborgsList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSedes() {
      this.sedes = []
      axios
        .post(globals.APIURL + "org/fo", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.Campus.length > 0) {
            this.selectedSede = auth.getSelectedSede();
            this.allSedesList = res.data.Campus;
            if (auth.getRoleName() !== "SUPER") {
              for (let i = 0; i < res.data.Campus.length; i++) {
                const element = res.data.Campus[i];
                if (element.devices.length > 0) {
                  this.sedes.push(element);
                }
              }

              let index = res.data.Campus.findIndex(item => item.uuid === auth.getSelectedSede())
              for (let i = 0; i < res.data.Campus[index].devices.length; i++) {
                const element = res.data.Campus[index].devices[i];
                if (element.type_device === "vspark") {
                  this.devices.push(element)
                }
              }
            } else {
              this.sedes = res.data.Campus;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getGroupZones() {// **NEW
      axios
        .post(globals.APIURL + "org/get-group-zones", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.status == 200 && res.data.data) {
            for (let i = 0; i < res.data.data.length; i++) {
              const element = res.data.data[i];
              
              if (element.users.includes(auth.getUsername())) {
                this.groupedZones.push(element)
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.selectedSede = null;
    if (auth.getUserEnterpriseID()) {
      this.getSedes();
      this.getPermissionGroups()
      this.getSuborgs()
      this.getGroupZones()
    }
    this.$vuetify.theme.dark = true;
    this.buildMenu(this.$route.name)
    this.buildParams()
    console.log("VERSION:", globals.VERSION)

    this.$bus.$on("logged", () => {
      this.isAuth = auth.loggedIn();
      this.userRole = auth.getRoleName();
      this.username = auth.getUsername();
      this.organizationName = auth.getUserEnterpriseName();
      this.getSedes();
      this.getPermissionGroups()
      this.getSuborgs()
      this.getGroupZones()// **NEW
    });
  },
  beforeUpdate() {
    if (this.$route.name === "dashCars") {
      this.vanguardAI = false;
      this.vanguardPark = true;
      this.vanguardAccess = false;
      this.selectedModule = "dashCars";
    } else if (this.$route.name === "dashboard" || this.$route.name === "registrations" || this.$route.name === "entryHistory" || this.$route.name === "nominal") {
      this.vanguardAI = false;
      this.vanguardPark = false;
      this.vanguardAccess = true;
      this.selectedModule = "dashboard";
    } else if (this.$route.name === "vision" || this.$route.name === "settings") {
      this.vanguardAI = true;
      this.vanguardPark = false;
      this.vanguardAccess = false;
      this.selectedModule = "vision";
    }
  },
};
</script>
<style scoped>
.border {
  margin-left: 80px;
  margin-top: 52px;
}
/* media query 1000px */
@media (max-width: 1250px) {
  .border {
    margin-left: 0px;
    margin-top: 52px;
  }
}

.v-input.v-input--hide-details.v-input--dense.theme--dark.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-select {
  max-width: 170px !important;
}
</style>